import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

function BasicModal({
	buttonVariant,
	buttonSize,
	disabled,
	buttonStartIcon,
	buttonText,
	content,
}) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Button
				variant={buttonVariant}
				size={buttonSize}
				startIcon={buttonStartIcon}
				disabled={disabled}
				onClick={handleOpen}>
				{buttonText}
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>{content}</Box>
			</Modal>
		</>
	);
}

export default BasicModal;
